




























import { Component, Vue, Prop } from "vue-property-decorator";
import InitialsBadge from "./InitialsBadge.vue";
import { secondaryBody } from "@/helpers/styleClassHelpers";
import CustomTag from "./CustomTag.vue";

@Component({
	components: {
		"initials-badges": InitialsBadge,
		"custom-tag": CustomTag,
	},
})
export default class DataTableCustom extends Vue {
	@Prop({ default: () => [] }) header!: any[];
	@Prop({ default: () => [] }) data!: any[];

	secondaryBody = secondaryBody;
}
