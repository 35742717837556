


























import { differenceInYears, parseISO } from "date-fns";
import { Component, Vue, Prop } from "vue-property-decorator";
import { getFieldContent } from "@/helpers/utils";
import InitialsBadge from "./InitialsBadge.vue";

@Component({
	components: {
		"initials-badge": InitialsBadge,
	},
})
export default class EntityHeader extends Vue {
	@Prop({ default: () => [] }) model!: any;
	@Prop({ default: () => [] }) modelHeaders!: any[];
	@Prop({ default: () => [] }) modalIcons!: any;
	@Prop({ default: true }) shouldShowHeaderName!: boolean;

	getFieldContent = getFieldContent;

	get modelName() {
		const modelFullName = this.model?.name + (this.model?.lastName ? " " + this.model.lastName : "");
		return modelFullName;
	}
}
