import { IInsurerPlan } from "./insurer_plan";

export enum ServicePlanKind {
	PSYCHOTHERAPY = "PSYCHOTHERAPY",
	PHYSIOTHERAPY = "PHYSIOTHERAPY",
	PHONOTHERAPY = "PHONOTHERAPY",
	OCCUPATIONAL_THERAPY = "OCCUPATIONAL_THERAPY",
	REFUND = "REFUND",
	HOME_CARE = "HOME_CARE",
	VACCINE = "VACCINE",
	PHARMACY_DISCOUNT = "PHARMACY_DISCOUNT",
	REMOVAL = "REMOVAL",
	OTHERS = "OTHERS",
}

const servicePlanKindTranslated: Record<ServicePlanKind, string> = {
	[ServicePlanKind.PSYCHOTHERAPY]: "PSICOTERAPIA",
	[ServicePlanKind.PHYSIOTHERAPY]: "FISIOTERAPIA",
	[ServicePlanKind.PHONOTHERAPY]: "FONOTERAPIA",
	[ServicePlanKind.OCCUPATIONAL_THERAPY]: "TERAPIA OCUPACIONAL",
	[ServicePlanKind.REFUND]: "REEMBOLSO",
	[ServicePlanKind.HOME_CARE]: "CUIDADOS DOMICILIARES",
	[ServicePlanKind.VACCINE]: "VACINA",
	[ServicePlanKind.PHARMACY_DISCOUNT]: "DESCONTO FARMÁCIA",
	[ServicePlanKind.REMOVAL]: "REMOÇÃO",
	[ServicePlanKind.OTHERS]: "OUTRAS",
};

export function translateServicePlanKind(status?: ServicePlanKind): string {
	if (!status) return "";
	return servicePlanKindTranslated[status];
}

export interface IServicePlan {
	serviceKind: ServicePlanKind;
	otherServiceKind: string;
	description: string;
	insurerPlan?: IInsurerPlan;
}
