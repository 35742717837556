
































import { Component, Prop, Vue } from "vue-property-decorator";
import { summarizeText } from "@/helpers/index";
import CustomTag from "./CustomTag.vue";
import CustomInput from "./Input.vue";

export type tagVariant = "primary" | "secondary";

@Component({
	components: {
		"custom-input": CustomInput,
		"custom-tag": CustomTag,
	},
})
export default class MultipleTextInput extends Vue {
	@Prop({ default: "" }) label!: string;
	@Prop({ default: "" }) error!: string;
	@Prop({ default: "" }) placeholder!: string;
	@Prop({ default: () => [] }) multipleText!: string[];
	@Prop({ default: (event: Event) => null }) onChange!: (multipleText: string[]) => void;
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: false }) required!: boolean;
	@Prop({ default: false }) isCustom!: boolean;
	@Prop({ default: () => null }) removeCustom!: Function;
	@Prop({ default: () => null }) translateCustom!: Function;

	text = "";
	summarizeText = summarizeText;

	onTextChange() {
		if (this.onChange && typeof this.onChange === "function") {
			this.onChange(this.multipleText);
		}
	}

	async removeText(index: number, service: any) {
		if (this.disabled) {
			return;
		}
		if (this.isCustom) {
			await this.removeCustom?.(service);
		}
		this.multipleText.splice(index, 1);
		this.onTextChange();
	}

	saveText() {
		if (this.text === "") {
			return;
		}

		this.multipleText.push(this.text);
		this.onTextChange();
		this.text = "";
	}
}
