









































































































































































import { BButton, BCard, BCardText, BOverlay } from "bootstrap-vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { translateGender, translateUserRole } from "@/interfaces/user";

import { requireConfirmation, showErrorAlert, showSuccessAlert, hasMissingRequiredKeys } from "../../helpers";
import {
	addressFromPostalCode,
	VALID_POSTAL_CODE_LENGTH,
	viaCEPKeysToAdaptName,
	viaCEPKeysToAdaptNameSecondary,
} from "@/api/cep";
import EntityField from "./EntityField.vue";
import CustomButton from "../../layouts/components/Button.vue";
import InitialsBadge from "@/layouts/components/InitialsBadge.vue";
import CustomSelect from "@/layouts/components/Select.vue";
import CustomInput from "@/layouts/components/Input.vue";
import MultipleTextInput from "@/layouts/components/MultipleTextInput.vue";
import EntityHeader from "@/layouts/components/EntityHeader.vue";
import { ISolution, SolutionStatus } from "@/interfaces/solution";
import { translateServicePlanKind } from "@/interfaces/service_plan";
import { FormColumns } from "@/interfaces/form_columns";
import { toIsoDate } from "@/helpers/calendar";

export type titleVariant = "primary" | "secondary";
export type containerVariant = "primary" | "secondary";

@Component({
	components: {
		EntityField,
		BButton,
		BCard,
		BCardText,
		BOverlay,
		CustomButton,
		InitialsBadge,
		CustomSelect,
		CustomInput,
		MultipleTextInput,
		EntityHeader,
	},
})
export default class EntityForm extends Vue {
	@Prop() model: any;
	@Prop() formColumns: FormColumns[];
	@Prop() secondaryFormColumns: FormColumns[];
	@Prop({ default: false }) isSecondaryFormColumnsToSend: boolean;
	@Prop({ default: () => [] }) joinKeys: string[];
	@Prop({ default: () => [] }) entityFormHeaders: any;
	@Prop({ default: true }) shouldShowHeaderName: boolean;
	@Prop({ default: () => {} }) defaultEntity: any;
	@Prop({ default: true }) enableDelete: boolean;
	@Prop({ default: true }) shouldFullReload: boolean;
	@Prop({ default: "" }) title: string;
	@Prop({ default: "" }) kind: string;
	@Prop({ default: false }) withPostalCode: boolean;
	@Prop({ default: false }) isTableAndForm: boolean;
	@Prop({ default: "primary" }) titleKind: titleVariant;
	@Prop({ default: "primary" }) formContainerVariant: containerVariant;
	@Prop({ default: "" }) userName: string;
	@Prop({ default: "" }) entityId: string;
	@Prop({ default: "" }) formStyleVariant: string;
	@Prop({ default: true }) isReadOnlyInitialValue: boolean;
	@Prop({ default: false }) showForm!: boolean;
	@Prop({ default: () => null }) onInputChange!: Function;
	@Prop({ default: () => null }) onSuccessfullyLoadingEntity!: Function;
	@Prop({ default: () => null }) onRemoveEntity!: Function;
	@Prop({ default: () => null }) onCreateOrUpdateEntity!: Function;
	@Prop({ default: () => null }) customRemove!: Function;
	@Prop({ default: true }) shouldGoToPreviousPageAfterRemove!: boolean;
	@Prop({ default: () => [] }) formActions: {
		name: string;
		icon: string;
		color: string;
		action: any;
	}[];
	@Prop({ default: "id" }) idParamKey: string;
	@Prop({ default: "" }) idRead: string;
	@Prop({ default: "Os dados foram registrados." }) onSuccessText: string;
	@Prop({ default: true }) loadMounted: boolean;
	@Prop({ default: false }) withEntityHeader: boolean;
	@Prop({ default: false }) emitAfterCreate: boolean;
	@Prop({ default: true }) withBackButton: boolean;
	@Prop({ default: true }) shouldLoadEntityOnMounted: boolean;
	@Prop({ default: true }) shouldIncludeFormSpacing: boolean;
	@Prop({ default: true }) withCreateOrUpdateButton: boolean;
	@Prop() additionalModel: any;
	@Prop({ default: () => ({}) }) cleanFormKeys: any;
	@Prop({ default: true }) withTitle!: boolean;
	@Prop() buttonText: { text: string; alternativeText: string };
	@Prop({ default: () => {} }) customEntity!: any;
	@Prop({ default: false }) isCustomEntity!: boolean;
	@Prop({ default: true }) isLoadEntity!: boolean;
	@Prop({ default: () => {} }) newErrors!: any;
	@Prop({ default: () => null }) toggleUnlockEdition!: Function;
	@Prop({ default: () => [] }) customButtons!: any;

	keyErrors = [] as string[];
	errorsInRequest = [] as any[];

	@Watch("newErrors", { deep: true })
	setNewErrors() {
		this.errorsInRequest = this.newErrors.response.properties;
	}

	translateService(service: any) {
		return `${translateServicePlanKind(service.serviceKind)}${
			service.otherServiceKind ? " - " + service.otherServiceKind : ""
		} - ${service.description}`;
	}

	updateError(fieldKey: string, status: boolean) {
		const indexKey = this.keyErrors.findIndex(key => key === fieldKey);
		if (status) {
			if (indexKey !== -1) {
				this.keyErrors.splice(indexKey, 1);
			}
		} else {
			if (indexKey === -1) {
				this.keyErrors.push(fieldKey);
			}
		}
	}
	//TODO: Trocar essa parte para ficar mais generico
	async createService() {
		this.entity.servicePlans.push(this.entityService);
		this.entityService = {};
	}
	//TODO: Trocar essa parte para ficar mais generico
	async removeService() {
		await requireConfirmation(
			"Confirma a exclusão do serviço?",
			"É necessário salvar para que essa remoção tenha efeito.",
			"Excluir",
		);
	}
	// <!-- //TODO: Trocar essa parte para ficar mais generico -->
	async updateSolution(status: string) {
		this.isEntityLoading = true;
		try {
			await this.model.updateSolutionStatus(this.$router.currentRoute.params.id, {
				status: status,
			});
			this.loadEntity();
			showSuccessAlert("Solução salva com sucesso");
		} catch (error) {
			showErrorAlert((error as any)?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		}
		this.isEntityLoading = false;
	}

	additionalEntityDescription: any = [];
	entityDescription: any = [];
	entityService: any = {};
	entity: any = {};
	originalEntity: any = {};
	formId = "";

	translateGender = translateGender;
	translateUserRole = translateUserRole;

	formPrimaryStyle = "w-full mt-2 overflow-auto py-1 pr-4 scroll h-auto";
	formSecondaryStyle = "w-full overflow-auto py-1 scroll";
	formTertiaryStyle = "";

	containerTertiaryStyle = "";
	containerPrimaryStyle = "flex flex-col items-center";
	containerSecondaryStyle = "flex flex-col lg:flex-row";

	headerButtons = [
		{ icon: "edit-icon.svg", variant: "tertiary", buttonAction: this.handleEditionLock.bind(null, false) },
		{ icon: "trash-icon.svg", variant: "tertiary", buttonAction: this.remove },
	];

	get actionButtons() {
		return [...this.headerButtons, ...this.customButtons];
	}

	isEntityLoading = false;

	roundStyle = "font-bold text-journey-black bg-journey-white p-1 shadow-md rounded-lg whitespace-nowrap";
	flatStyle = "font-bold text-journey-black bg-journey-white whitespace-nowrap text-xl mt-1 mb-2";
	isReadOnly = true;

	async mounted() {
		if (this.loadMounted) {
			await this.loadEntity();
		}
		await this.changeFields();
		this.isReadOnly = this.formId !== "novo" ? this.isReadOnlyInitialValue : false;
	}

	@Watch("isReadOnlyInitialValue")
	changeEditionLock() {
		this.isReadOnly = this.isReadOnlyInitialValue;
	}

	handleEditionLock(shouldBeLocked: boolean) {
		if (this.formId === "novo") {
			shouldBeLocked = false;
		}
		this.isReadOnly = shouldBeLocked;
		return this.formColumns.forEach(column => {
			if (typeof column !== "string") {
				column.key === "id"
					? (column.isReadOnly = true)
					: column.hasOwnProperty("isReadOnly") && (column.isReadOnly = shouldBeLocked);
			}
		});
	}

	get styleForm() {
		switch (this.formStyleVariant) {
			case "primary":
				return this.formPrimaryStyle;
			case "secondary":
				return this.formSecondaryStyle;
			default:
				return "";
		}
	}

	get formContainerLayout() {
		switch (this.formContainerVariant) {
			case "primary":
				return this.containerPrimaryStyle;
			case "secondary":
				return this.containerSecondaryStyle;
			default:
				return this.containerTertiaryStyle;
		}
	}
	get titleStyle() {
		switch (this.titleKind) {
			case "primary":
				return this.flatStyle;
			case "secondary":
				return this.roundStyle;
		}
	}

	get buttonDisabled() {
		let buttonDisabled = true;
		if (!hasMissingRequiredKeys(this.formColumns, this.entity) && !this.keyErrors.length) {
			buttonDisabled = false;
		}
		this.$emit("button-status", buttonDisabled);
		return buttonDisabled;
	}

	@Watch("$route")
	routeChanged(route: any) {
		if (route.params[this.idParamKey] !== this.formId) {
			this.loadEntity();
		}
	}

	@Watch("entityId")
	idChange() {
		if (this.entityId !== this.formId) {
			this.loadEntity();
		}
	}

	async loadEntityBaseFunctionality() {
		this.entity = { ...this.defaultEntity };
		if (this.additionalModel) {
			this.additionalEntityDescription = await this.additionalModel.getDescription();
		}
		this.entityDescription = await this.model.getDescription();
	}

	async loadEntity() {
		this.isEntityLoading = true;
		try {
			await this.loadEntityBaseFunctionality();
			if (!this.shouldLoadEntityOnMounted) {
				return;
			}

			this.formId = this.idRead || this.$route.params[this.idParamKey];
			if (this.isLoadEntity) {
				if (this.formId === "novo") {
					this.handleEditionLock(false);
				}

				if (this.formId !== "novo" && !this.$route.path.includes("/necessidades")) {
					this.entity = await this.model.read(this.formId);
					if (this.$route.path.includes("/solucoes")) {
						this.defaultEntity["isIndividualForClient.id"] = this.entity["isIndividualForClient.id"]
							? this.entity["isIndividualForClient.id"]
							: null;
					}
					this.entity = { ...this.defaultEntity, ...this.entity }; //aqui
					this.originalEntity = { ...this.entity };
				}
				if (this.formId !== "novo" && this.kind === "editionForm") {
					this.formColumns.forEach(column => (column.isReadOnly = true));
				} else if (this.formId !== "novo" && this.$route.path.includes("/necessidades")) {
					this.formId = this.entityId;
					this.entity = await this.model.read(this.formId);
					this.originalEntity = { ...this.entity };
					this.formColumns.forEach(column => (column.isReadOnly = true));
				} else if (this.formId === "novo" && (this.kind === "editionForm" || this.kind === "tableAndForm")) {
					this.handleEditionLock(false);
				}
			} else {
				this.entity = this.customEntity;
			}
		} catch (error) {
			console.error(error);
		} finally {
			await this.onSuccessfullyLoadingEntity?.(this.entity);
			this.isEntityLoading = false;
		}

		this.formColumns.forEach((column: any) => {
			column.formatEntity ? this.getModelEntityId(column.key, column.relationKey) : null;
			column.getInformationOtherKey
				? this.getInformationOtherKey(column.key, column.otherKey, column.relationKeyId, column.relationKeyName)
				: null;
			column.customFormat ? column.customFunctionFormat(this.entity) : null;
		});
		this.isEntityLoading = false;
	}

	getInformationOtherKey(key: string, otherKey: string, relationKeyId: string, relationKeyName: string) {
		this.entity[otherKey]?.forEach((value: any) => {
			if (!this.entity[key]?.find((currentValue: any) => currentValue.id === value[relationKeyId])) {
				this.entity[key].push({ id: value[relationKeyId], name: value[relationKeyName] });
			}
		});
	}

	getModelEntityId(key: string, relationKey: string) {
		if (!this.entity[key]) {
			return;
		}
		const entityModel = this.entity[key]?.reduce((values: any[], currentEntityItem: any) => {
			const mountedKey = Object.keys(currentEntityItem)?.reduce(
				(relatedEntity, key) => {
					if (!currentEntityItem[key]) {
						return relatedEntity;
					}

					const keyParts = key.split(".");
					if (["id", "name", "description", "productName", "substanceName"].includes(keyParts[1])) {
						relatedEntity[relationKey][keyParts[1]] = currentEntityItem[key];
					}

					if (keyParts[1] === "corporateClient") {
						relatedEntity[relationKey].corporateClient = {};
						relatedEntity[relationKey].corporateClient.id = currentEntityItem["insurerPlan.corporateClient.id"];
						relatedEntity[relationKey].corporateClient.name = currentEntityItem["insurerPlan.corporateClient.name"];
					}

					return relatedEntity;
				},
				{ [relationKey]: {} } as any,
			);

			if (mountedKey) {
				values.push(mountedKey);
			}

			return values;
		}, []);

		if (entityModel?.length) {
			this.entity[key] = entityModel;
		} else {
			this.entity[key] = "";
		}
	}

	inputChange(value: any, key: string) {
		if (this.onInputChange && typeof this.onInputChange === "function") {
			this.onInputChange(value, key);
		}

		if (this.withPostalCode && (key === "address.postCode" || key === "secondaryAddress.postCode")) {
			const currentHeader = this.formColumns.find((entity: any) => entity.autoComplete);

			if (currentHeader?.autoComplete) {
				this.autocompleteByPostalCode(currentHeader?.key, currentHeader?.autoCompleteKeys, currentHeader.isReadOnly);
			}
		}
	}

	async autocompleteByPostalCode(key: string, autocompleteKeys: any[] = [], isReadOnly = false) {
		if (!this.entity[key] || isReadOnly) {
			return;
		}

		autocompleteKeys.forEach(autocompleteKey => {
			this.setReadOnly(autocompleteKey, false);
		});

		if (this.entity[key].length < VALID_POSTAL_CODE_LENGTH) {
			return;
		}
		const location = await addressFromPostalCode(this.entity[key].replace(/\D/g, ""));
		if (location.erro) {
			autocompleteKeys.forEach(autocompleteKey => {
				this.setReadOnly(autocompleteKey, false);
			});
			this.setError(key, "CEP inválido");
			return;
		}
		this.setError(key, "");
		autocompleteKeys.forEach(autocompleteKey => {
			const propertyValue =
				key === "address.postCode"
					? location[viaCEPKeysToAdaptName[autocompleteKey]]
					: location[viaCEPKeysToAdaptNameSecondary[autocompleteKey]];
			//WARN: Só é alterado o endereço com base no viaCEP, caso os campos tiverem vazios
			if (!this.entity[autocompleteKey]) {
				this.setReadOnly(autocompleteKey, !!propertyValue);
				Vue.set(this.entity, autocompleteKey, propertyValue);
			}
		});
	}

	setError(key: string, error: string) {
		this.formColumns.forEach(column => {
			if (column.key == key) {
				column.error = error;
			}
		});
	}

	setReadOnly(key: string, status: boolean) {
		this.formColumns.forEach(column => {
			if (column.key == key) {
				Vue.set(column, "isReadOnly", status);
			}
		});
	}

	fields: any = [];

	@Watch("entity", { deep: true })
	@Watch("formColumns", { deep: true })
	async changeFields() {
		if (!this.entityDescription) {
			return [];
		}

		this.fields = this.formColumns
			.filter(column =>
				typeof column === "string" ? true : !column.validationToShow || column.validationToShow(this.entity),
			)
			.map(column => {
				if (typeof column === "string") {
					return this.entityDescription.find((field: any) => field.key === column) || {};
				} else {
					let fieldDescription: any =
						this.entityDescription.find((field: any) => field.key === column.key) ||
						(column.key === "id" ? { ...column } : {});

					let options = [];
					if (column.optionsFrom) {
						options = this.entityDescription?.find((field: any) => field.key === column.optionsFrom)?.options;
					}
					// TODO: Validar logica do field description multiselect e id
					if (
						column.kind === "multiselect" ||
						column.kind === "select" ||
						column.kind === "readOnly" ||
						column.kind === "table"
					) {
						fieldDescription = { ...fieldDescription, ...column };
					}

					return {
						...fieldDescription,
						name: column.name ?? fieldDescription.name,
						isReadOnly: (column.isReadOnly || (column.updateReadOnly && this.formId !== "novo")) ?? false,
						createOnly: column.createOnly,
						updateOnly: column.updateOnly,
						inputKind: column.inputKind,
						kind: column.kind || fieldDescription.kind,
						onTransform: column.onTransform,
						validationField: column.validationField,
						modifyValue: column.modifyValue,
						messageError: column.messageError,
						autoComplete: column.autoComplete,
						autoCompleteKeys: column.autoCompleteKeys,
						error: column.error,
						mask: column.mask,
						relationKey: column.relationKey,
						required: column.required,
						options: fieldDescription.options || column.options || options,
						shouldShowSelectAllButton: column.shouldShowSelectAllButton,
						titleModal: column.titleModal,
						component: column.component,
						removeCustom: column.removeCustom,
						translateCustom: column.translateCustom,
						fetchOptions: column.fetchOptions,
						onSearch: column.onSearch,
						shouldLoadManyToOneOptions: column.shouldLoadManyToOneOptions,
						shouldDisplayAnyway: column.shouldDisplayAnyway,
						columns: column.columns,
						customFormat: column.customFormat,
						customFunctionFormat: column.customFunctionFormat,
						key: column.key,
						shouldShowAddButton: column.shouldShowAddButton,
						onAddButtonFunciton: column.onAddButtonFunciton,
						emptyValue: column.emptyValue,
						shouldShowHelp: column.shouldShowHelp,
						helpText: column.helpText,
						showFullName: column.showFullName,
					};
				}
			})
			.filter(
				column => !((column.createOnly && this.formId !== "novo") || (column.updateOnly && this.formId === "novo")),
			);
	}

	get createOrUpdateButtonText() {
		if (this.buttonText) {
			return this.idRead === "novo" ? this.buttonText.text : this.buttonText.alternativeText;
		}
		return this.formId === "novo" ? "Cadastrar" : "Salvar";
	}

	get validateOrInvalidateButtonText() {
		return this.entity.status === SolutionStatus.CONFIRMED ? "Invalidar Solução" : "Validar Solução";
	}

	async createOrUpdate() {
		Object.keys(this.entity).forEach(key => {
			const field = this.fields.find((_field: any) => _field.key === key);
			if (field?.kind === "number") {
				this.entity[key] = Number(this.entity[key]);
			}

			if (field?.kind === "time" && this.entity[key]?.length === 5) {
				const newDate = new Date();
				const [hours, minutes] = this.entity[key].split(":");
				newDate.setHours(hours);
				newDate.setMinutes(minutes);
				this.entity[key] = newDate.toISOString();
			}
		});

		if (this.model.endpoint !== "admin/insurerPlan") {
			await this.cleanForm();
		}

		try {
			this.isEntityLoading = this.model.endpoint !== "admin/insurerPlan" && true;
			if (
				this.formId === "novo" &&
				!this.$route.path.includes("/necessidades") &&
				this.model.endpoint !== "admin/insurerPlan"
			) {
				const entity = await this.model.create(this.entity);
				this.formId = entity.id;
				if (this.model.endpoint === "admin/corporateClient") {
					this.$emit("updateServiceList");
				}
				if (!this.emitAfterCreate) {
					await this.$router.replace(this.$router.currentRoute.path.replace("novo", entity.id));
					location.reload();
				}
				this.entity = await this.model.read(this.formId);
			} else if (this.formId === "novo" && this.$route.path.includes("/necessidades")) {
				const entity = await this.model.create(this.entity);
				this.formId = entity.id;
				this.$router.back();
			} else if (this.model.endpoint === "admin/insurerPlan") {
				const action = this.idRead === "novo" ? "add" : "update";
				this.$emit("addInsurerPlan", this.entity, action);
			} else {
				if (this.model.endpoint === "admin/corporateClient") {
					this.$emit("updateServiceList");
				}
				await this.model.update(this.formId, this.entity);

				if (this.shouldFullReload) {
					location.reload(); // recarrega a entidade para garantir consistência
				} else {
					this.isEntityLoading = false;
					this.$emit("onReload");
				}
			}
			this.toggleUnlockEdition ? this.toggleUnlockEdition() : this.handleEditionLock(true);
			this.onCreateOrUpdateEntity?.();
			showSuccessAlert(this.onSuccessText);
		} catch (error: any) {
			this.isEntityLoading = false;
			console.error(error);
			this.errorsInRequest = error.response.properties;
			showErrorAlert(error?.response.message || error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		}
	}

	async cleanForm() {
		const keysToSend = !this.isSecondaryFormColumnsToSend
			? this.formColumns.map(key => {
					if (typeof key === "string") {
						return key;
					} else {
						return key.key;
					}
			  })
			: this.secondaryFormColumns.map(key => {
					if (typeof key === "string") {
						return key;
					} else {
						return key.key;
					}
			  });

		Object.keys(this.entity).forEach(key => {
			const shouldKeepKey = !!this.cleanFormKeys.holdKeys?.find((holdKey: string) => holdKey === key);
			//TODO: Melhorar essa validação deixando mais genérica para ser possível passar quais propriedades deverão ser excluidas do payload caso estejam incluidas nos casos definidos abaixo
			if (
				!shouldKeepKey &&
				((((key.split(".")[0] === "address" || key === "solutionQualifications") &&
					[undefined, null, ""].includes(this.entity[key])) ||
					[undefined, null, 0].includes(this.entity[key])) ??
					!this.entity[key])
			) {
				delete this.entity[key];
				return;
			}

			if (this.entity[key] === "") {
				const column = this.formColumns.find(column => column.key === key);
				this.entity[key] = column?.emptyValue ?? "";
			}

			this.cleanFormKeys?.deleteKeys?.forEach((keyToDelete: string | { key: string; type: string }) => {
				if (typeof keyToDelete === "string") {
					if (keyToDelete === key) {
						delete this.entity[key];
					}
				} else if (keyToDelete.type === "array") {
					//WARN for array of objects
					const parts = keyToDelete.key.split(".");
					const entityKey = parts[0];
					const itemKeyToDelete = parts[1];
					this.entity?.[entityKey]?.forEach((object: any) => {
						delete object[itemKeyToDelete];
					});
				}
			});

			if (!keysToSend.filter(text => text.includes(key)).length && !this.cleanFormKeys.holdKeys?.includes(key)) {
				delete this.entity[key];
			}
		});
	}

	async remove() {
		await requireConfirmation("Confirma a exclusão?", "Não será possível reverter esta operação.", "Excluir");

		this.isEntityLoading = true;
		try {
			if (this.customRemove) {
				this.customRemove(this.entity);
			} else {
				await this.model.delete(this.entity.id);
			}
			showSuccessAlert("O registro foi excluído.");
			this.onRemoveEntity?.();
			if (this.shouldGoToPreviousPageAfterRemove) {
				this.$router.back();
			}
		} catch (error: any) {
			console.error(error);
			showErrorAlert(error?.friendlyMessage || error.message || "Ocorreu um erro. Tente novamente.");
		}
		this.isEntityLoading = false;
	}

	goBack() {
		this.$router.back();
	}
}
