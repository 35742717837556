import { IAvailability } from "./availability";
import { IAddress } from "@/interfaces/address";
import { Gender } from "./gender.enum";
import { ICategory } from "./solution_category";
import { ISolutionNeed } from "./solution_need";
import { AppointmentModality, IAppointment } from "./appointment";
import { INeed } from "./need";
import { Client } from "@/entities/client";
import { IEnterpriseSolution } from "./enterprise_solution";

export enum SubcategoryEnum {
	IMAGE = "IMAGE",
	AUDIO = "AUDIO",
	VIDEO = "VIDEO",
}

export enum SubcategoryTypeVisualText {
	IMAGE = "Ver imagem",
	VIDEO = "Ver vídeo",
	AUDIO = "Abrir áudio",
}

export enum SubcategoryTypeTranslation {
	IMAGE = "Imagem",
	VIDEO = "Vídeo",
	AUDIO = "Áudio",
}

export enum SolutionStatus {
	REFUSED = "REFUSED",
	VALIDATE = "VALIDATE",
	CONFIRMED = "CONFIRMED",
	INACTIVATED = "INACTIVATED",
}
const solutionStatusTranslated: Record<SolutionStatus, string> = {
	[SolutionStatus.REFUSED]: "Recusado",
	[SolutionStatus.VALIDATE]: "Validar",
	[SolutionStatus.CONFIRMED]: "Confirmado",
	[SolutionStatus.INACTIVATED]: "Inativada",
};

export function translateSolutionStatus(status?: SolutionStatus): string {
	if (!status) return "";
	return solutionStatusTranslated[status];
}

export interface ISolution {
	id: string;
	category: ICategory;
	subcategory: SubcategoryEnum;
	isFavorite: boolean;
	status: SolutionStatus;
	name: string;
	description: string;
	attachmentUrl?: string;
	gender: Gender;
	address: IAddress;
	phoneNumber: string;
	distanceInKm: number;
	solutionQualifications: any[];
	solutionInsurerPlans: any[];
	solutionNeeds: ISolutionNeed[];
	ratingValueAverage: number;
	examOrDoctorName: string;
	isIndividualForClient: Client;
	enterpriseSolutions: IEnterpriseSolution[];

	//local
	relatedNeed?: INeed | null;
	conversationContext?: string;
	notFoundView?: boolean;
	isFilterModalOpen?: boolean;
	isRemovingFilters?: boolean;
	filterVisualData?: string;
	appointment?: IAppointment;
	isIndividualForClientId: string;
}

export enum Specialties {
	ALLERGOLOGIST = "ALLERGOLOGIST",
	CARDIOLOGIST = "CARDIOLOGIST",
	CARDIOVASCULAR_SURGERY = "CARDIOVASCULAR_SURGERY",
	HAND_SURGERY = "HAND_SURGERY",
	HEAD_AND_NECK_SURGERY = "HEAD_AND_NECK_SURGERY",
	DIGESTIVE_SYSTEM_SURGERY = "DIGESTIVE_SYSTEM_SURGERY",
	GENERAL_SURGERY = "GENERAL_SURGERY",
	ONCOLOGICAL_SURGERY = "ONCOLOGICAL_SURGERY",
	PEDIATRIC_SURGERY = "PEDIATRIC_SURGERY",
	PLASTIC_SURGERY = "PLASTIC_SURGERY",
	THORACIC_SURGERY = "THORACIC_SURGERY",
	VASCULAR_SURGERY = "VASCULAR_SURGERY",
	CLINICAL = "CLINICAL",
	DERMATOLOGIST = "DERMATOLOGIST",
	ENDOCRINOLOGIST = "ENDOCRINOLOGIST",
	GASTROENTEROLOGIST = "GASTROENTEROLOGIST",
	MEDICAL_GENETICS = "MEDICAL_GENETICS",
	GERIATRIC = "GERIATRIC",
	GYNECOLOGIST = "GYNECOLOGIST",
	GYNECOLOGIST_AND_OBSTETRIC = "GYNECOLOGIST_AND_OBSTETRIC",
	HEMATOLOGIST = "HEMATOLOGIST",
	INFECTOLOGIST = "INFECTOLOGIST",
	MASTOLOGIST = "MASTOLOGIST",
	FAMILY_DOCTOR = "FAMILY_DOCTOR",
	NEPHROLOGIST = "NEPHROLOGIST",
	NUTROLOGIST = "NUTROLOGIST",
	NEUROSURGERY = "NEUROSURGERY",
	OPHTHALMOLOGIST = "OPHTHALMOLOGIST",
	ONCOLOGIST = "ONCOLOGIST",
	ORTHOPEDIST = "ORTHOPEDIST",
	OTOLARYNGOLOGIST = "OTOLARYNGOLOGIST",
	PEDIATRICIAN = "PEDIATRICIAN",
	PNEUMOLOGIST = "PNEUMOLOGIST",
	PSYCHIATRIST = "PSYCHIATRIST",
	RHEUMATOLOGIST = "RHEUMATOLOGIST",
	UROLOGIST = "UROLOGIST",
}

export const specialtiesTranslated: Record<Specialties, string> = {
	[Specialties.ALLERGOLOGIST]: "Alergologista",
	[Specialties.CARDIOLOGIST]: "Cardiologista",
	[Specialties.CARDIOVASCULAR_SURGERY]: "Cirurgia cardiovascular",
	[Specialties.HAND_SURGERY]: "Cirurgia da mão",
	[Specialties.HEAD_AND_NECK_SURGERY]: "Cirurgia de cabeça e pescoço",
	[Specialties.DIGESTIVE_SYSTEM_SURGERY]: "Cirurgia do aparelho digestivo",
	[Specialties.GENERAL_SURGERY]: "Cirurgia geral",
	[Specialties.ONCOLOGICAL_SURGERY]: "Cirurgia oncológica",
	[Specialties.PEDIATRIC_SURGERY]: "Cirurgia pediátrica",
	[Specialties.PLASTIC_SURGERY]: "Cirurgia plástica",
	[Specialties.THORACIC_SURGERY]: "Cirurgia torácica",
	[Specialties.VASCULAR_SURGERY]: "Cirurgia vascular",
	[Specialties.CLINICAL]: "Clínico",
	[Specialties.DERMATOLOGIST]: "Dermatologista",
	[Specialties.ENDOCRINOLOGIST]: "Endocrinologista",
	[Specialties.GASTROENTEROLOGIST]: "Gastroenterologista",
	[Specialties.MEDICAL_GENETICS]: "Genética médica",
	[Specialties.GERIATRIC]: "Geriatra",
	[Specialties.GYNECOLOGIST]: "Ginecologista",
	[Specialties.GYNECOLOGIST_AND_OBSTETRIC]: "Ginecologista e obstetra",
	[Specialties.HEMATOLOGIST]: "Hematologista",
	[Specialties.INFECTOLOGIST]: "Infectologista",
	[Specialties.MASTOLOGIST]: "Mastologista",
	[Specialties.FAMILY_DOCTOR]: "Médico de família",
	[Specialties.NEUROSURGERY]: "Neurocirurgia",
	[Specialties.NUTROLOGIST]: "Nutrólogo",
	[Specialties.OPHTHALMOLOGIST]: "Oftalmologista",
	[Specialties.ONCOLOGIST]: "Oncologista",
	[Specialties.OTOLARYNGOLOGIST]: "Otorrinolaringologista",
	[Specialties.PEDIATRICIAN]: "Pediatra",
	[Specialties.PNEUMOLOGIST]: "Pneumologista",
	[Specialties.PSYCHIATRIST]: "Psiquiatra",
	[Specialties.RHEUMATOLOGIST]: "Reumatologista",
	[Specialties.UROLOGIST]: "Urologista",
	[Specialties.NEPHROLOGIST]: "Nefrologista",
	[Specialties.ORTHOPEDIST]: "Ortopedista",
};

export function translateSpecialties(status?: Specialties): string {
	if (!status) return "";
	return specialtiesTranslated[status];
}
