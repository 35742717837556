








































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
	components: {},
})
export default class MultipleSelectButton extends Vue {
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event) => void;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) value!: string;
	@Prop({ default: () => [] }) buttonOptions!: string[];
	@Prop({ default: "" }) label!: string;
	@Prop({ default: "" }) error!: string;
	@Prop({ default: false }) required!: boolean;
	@Prop({ default: false }) isReadOnly!: boolean;
	@Prop({ default: "" }) relationKey!: string;

	public selectedStyle =
		"border-1 rounded-md text-sm text-center px-2 py-1.5 h-auto font-bold flex justify-center whitespace-nowrap border-journey-green text-journey-green";
	public notSelectedStyle =
		"border-1 rounded-md text-sm w-auto text-center px-2 py-1.5 h-auto font-bold border-journey-gray text-journey-gray";

	get model() {
		return this.value;
	}

	set model(value) {
		this.$emit("input", value);
	}
}
