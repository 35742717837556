



































































































































































import { secondaryBody, primaryBody } from "@/helpers/styleClassHelpers";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getFieldContent } from "@/helpers/utils";
import DataTableCustom from "./DataTableCustom.vue";
import CustomCheckbox from "./Checkbox.vue";
import { BPagination } from "bootstrap-vue";
import GuideBalloon from "./GuideBalloon.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

type TableVariant = "primary" | "secondary";

@Component({
	components: {
		"data-table-custom": DataTableCustom,
		"custom-checkbox": CustomCheckbox,
		BPagination,
		GuideBalloon,
	},
})
export default class Table extends Vue {
	@Prop({ default: () => [] }) headers!: any;
	@Prop({ default: () => [] }) tableData!: any;
	@Prop({ default: "primary" }) variant!: TableVariant;
	@Prop() synchronizeSelected: boolean;
	@Prop({ default: () => null }) onClickLine!: (data: any) => void;

	secondaryBody = secondaryBody;
	primaryBody = primaryBody;

	getFieldContent = getFieldContent;

	selectAll: string[] = [];
	selected: any[] = [];
	subMenuOpen = {} as any;

	toggleSelectAll() {
		if (this.selectAll.length) {
			this.selected = [...this.tableData];
		} else {
			this.selected = [];
		}
	}

	toggleSelect() {
		if (this.selected.length !== this.tableData.length) {
			this.selectAll = [];
		} else {
			this.selectAll = ["selectAll"];
		}
	}

	tableRowStyle(kind?: string, style?: string) {
		switch (kind || style) {
			case "buttonSize":
				return "w-32 whitespace-nowrap pr-4";
			case "value":
				return ["whitespace-nowrap"];
			case "bold":
				return "font-bold";
			default:
				return "";
		}
	}

	mounted() {
		window.addEventListener("click", this.clickOut);
	}

	openSubMenu(header: any) {
		if (header === this.subMenuOpen) {
			this.subMenuOpen = {};
		} else {
			this.subMenuOpen = header;
		}
	}

	clickOut(e: any) {
		//Sempre sobe um nivel do elemento clicado, que é aonde fica a tag pai caso tenha clicado em algum item do menu.
		const itemMenu = e.path ? e.path[1] : undefined;
		//Verifica se o elemento clicado é um item do menu e se possui subMenu, caso negativo para qualquer uma ele fecha o subMenu aberto.
		if (itemMenu && (itemMenu.id !== "itemMenu" || !itemMenu.querySelector("#subMenu"))) {
			this.subMenuOpen = {};
		}
	}

	onClick(option: any) {
		this.$emit("clickOption", option);
	}

	actionsEvent(event: string, data: any) {
		this.$emit("actionsEvent", event, data);
	}

	clickItemHeader(header: any) {
		this.$emit("clickItemHeader", header);
	}

	addOptionAll(options: any[]) {
		if (!options || !options.length) {
			return [];
		}
		return [{ name: "Todos", value: "all" }, ...options];
	}

	@Watch("selected")
	emitSelected() {
		this.$emit("updateSelected", this.selected);
	}

	@Watch("synchronizeSelected")
	clearSelected() {
		this.selected = [];
	}

	beforeDestroy() {
		window.removeEventListener("click", this.clickOut);
	}
}
