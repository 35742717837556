






























import { Component, Prop, Vue } from "vue-property-decorator";
import {
	secondaryBody,
	primaryOrangeBody,
	secondaryOrangeBody,
	tertiaryBoldBody,
	tertiaryBody,
} from "@/helpers/styleClassHelpers";

@Component({})
export default class CustomTextArea extends Vue {
	@Prop({ default: "" }) label!: string;
	@Prop({ default: "" }) placeholder!: string;
	@Prop({ default: "" }) error!: string;
	@Prop({ default: "" }) value!: string;
	@Prop({ default: "4" }) rows!: string;
	@Prop({ default: false }) required!: boolean;
	@Prop({ default: false }) disabled!: boolean;

	secondaryBody = secondaryBody;
	primaryOrangeBody = primaryOrangeBody;
	secondaryOrangeBody = secondaryOrangeBody;
	tertiaryBoldBody = tertiaryBoldBody;
	tertiaryBody = tertiaryBody;
}
