import axios from "axios";

export const VALID_POSTAL_CODE_LENGTH = 9;

export async function addressFromPostalCode(postalCode: string) {
	return axios.get(`https://viacep.com.br/ws/${postalCode}/json/`).then(response => response.data);
}

export enum AddressKeys {
	addressStreet = "address.street",
	addressCity = "address.city",
	addressState = "address.state",
	addressPostalCode = "address.postalCode",
	addressNeighborhood = "address.neighborhood",
}

export enum AddressKeysSecondary {
	addressStreet = "secondaryAddress.street",
	addressCity = "secondaryAddress.city",
	addressState = "secondaryAddress.state",
	addressPostalCode = "secondaryAddress.postalCode",
	addressNeighborhood = "secondaryAddress.neighborhood",
}

// Adapta retorno da api ViaCep
export const viaCEPKeysToAdaptName: any = {
	[AddressKeys.addressStreet]: "logradouro",
	[AddressKeys.addressCity]: "localidade",
	[AddressKeys.addressState]: "uf",
	[AddressKeys.addressPostalCode]: "cep",
	[AddressKeys.addressNeighborhood]: "bairro",
};

export const viaCEPKeysToAdaptNameSecondary: any = {
	[AddressKeysSecondary.addressStreet]: "logradouro",
	[AddressKeysSecondary.addressCity]: "localidade",
	[AddressKeysSecondary.addressState]: "uf",
	[AddressKeysSecondary.addressPostalCode]: "cep",
	[AddressKeysSecondary.addressNeighborhood]: "bairro",
};
