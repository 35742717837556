
























































































































import { add, areIntervalsOverlapping, format, isAfter } from "date-fns";
import { Component, Prop, Vue } from "vue-property-decorator";
import VueSelect from "vue-select";

import { AvailabilityModality, IAvailability } from "../../interfaces/availability";

import CustomTag from "./CustomTag.vue";
import Calendar from "./Calendar.vue";
import Button from "./Button.vue";
import { isBetween, toIsoDate } from "@/helpers/calendar";
import isBefore from "date-fns/isBefore";
import { addMinutes, addWeeks, subSeconds } from "date-fns/esm";
import { IRange } from "@/interfaces/range";
import set from "date-fns/set";
import { showErrorAlert } from "@/helpers";

const currentDate = new Date();
const minutesInterval = ["00", "15", "30", "45"];

@Component({
	components: {
		Button,
		CustomTag,
		Calendar,
		VueSelect,
	},
})
export default class AvailabilitySelect extends Vue {
	@Prop({ default: "" }) label: string;
	@Prop({ default: "" }) error!: string;
	@Prop({ default: () => [] }) value: IAvailability[];
	@Prop({ default: () => [] }) modalities: { name: string; value: string }[];
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: false }) required!: boolean;

	currentCalendarDate = "";
	initialDate: Date | null = null;
	finalDate: Date | null = null;
	modality: AvailabilityModality | null = null;
	emptyListMessage = "Nenhuma opção encontrada.";
	timeDivisionInMinutes: number | null = null;
	weeksQuantityToRepeat: number | null = null;

	get todayIsoDate() {
		return toIsoDate(new Date());
	}

	get currentAvailabilities() {
		return this.value.filter(
			({ startDateTime }) => format(new Date(startDateTime), "yyyy-MM-dd") === this.currentCalendarDate.split("T")[0],
		);
	}

	get tags() {
		return this.currentAvailabilities.map(({ startDateTime, endDateTime }) =>
			this.formatStartAndEndDateTime(startDateTime, endDateTime),
		);
	}

	get datesSet() {
		return new Set(this.value.map(({ startDateTime }) => format(new Date(startDateTime), "yyyy-MM-dd")));
	}

	get rawAvailabilityOptions() {
		const maxAvailableHours = 24;
		const timeOptions = Array.from({ length: maxAvailableHours }, (_, index) =>
			minutesInterval.map(minutes => `${String(index).padStart(2, "0")}:${minutes}`),
		).flat(1);

		return timeOptions.map(time => {
			return {
				name: time,
				value: new Date(`${this.currentCalendarDate.split("T")[0] || format(new Date(), "yyyy-MM-dd")}T${time}:00`),
			};
		});
	}

	get availabilityOptions() {
		return this.rawAvailabilityOptions.filter(({ value }) => {
			return (
				isAfter(value, currentDate) &&
				this.availableRanges.some(({ start, end }) =>
					isBetween(value.toISOString(), { start, end: subSeconds(new Date(end), 1).toISOString() }),
				)
			);
		});
	}

	get finalDateOptions() {
		if (!this.initialDate) {
			return [];
		}
		//"rawAvailabilityOptions" is the ordered array, which is easier to handle "nextDayOptions"
		let finalAvailabilitiesOptions = [...this.rawAvailabilityOptions];
		let index =
			finalAvailabilitiesOptions.findIndex(({ value }) => value.getTime() === this.initialDate?.getTime()) + 1;
		let maxFinalDateOptionIndex = index + minutesInterval.length * 24;
		const optionsLength = finalAvailabilitiesOptions.length;

		const range = this.availableRanges.reverse().find(range => isBetween(this.initialDate!.toISOString(), range))!;
		const offset = maxFinalDateOptionIndex - optionsLength;
		const isNextDay = offset > 0;
		if (isNextDay) {
			const nextDayOptions = finalAvailabilitiesOptions
				.slice(0, offset)
				.map(({ name, value }) => ({ name, value: add(value, { days: 1 }) }));
			finalAvailabilitiesOptions.splice(optionsLength, 0, ...nextDayOptions);

			const lastEnd = this.currentAvailabilities[this.currentAvailabilities.length - 1]?.endDateTime;
			//if a existing availability with the last datetime ending after today (at the next day), then the user can't select any start datetime today with the end datetime at the next day
			if (!lastEnd || (lastEnd && isBefore(new Date(lastEnd), set(new Date(), { hours: 24, minutes: 0 })))) {
				range.end = nextDayOptions[nextDayOptions.length - 1].value.toISOString();
			}
		}

		return finalAvailabilitiesOptions
			.slice(index, maxFinalDateOptionIndex)
			.filter(({ value }) => isBetween(value.toISOString(), range));
	}

	get availableRanges() {
		if (!this.currentCalendarDate) {
			return [];
		}

		const ranges: IRange[] = [];
		let lastRange: IRange;
		const selectedCalendarDate = new Date(this.currentCalendarDate);
		const startOfDay = set(selectedCalendarDate, { hours: 0, minutes: 0, seconds: -1 }).toISOString();
		const endOfDay = set(selectedCalendarDate, { hours: 24, minutes: 0 }).toISOString();

		const firstStart = this.currentAvailabilities[0]?.startDateTime;
		if (firstStart) {
			ranges.push({
				start: startOfDay,
				end: firstStart as string,
			});
		}
		this.currentAvailabilities.forEach(({ startDateTime: start, endDateTime: end }, index) => {
			if (lastRange && lastRange.end !== start) {
				ranges.push(
					isAfter(new Date(lastRange.end), new Date(start))
						? ({ start, end: lastRange.end } as any)
						: ({ start: lastRange.end, end: start } as any),
				);
			}
			lastRange = { start, end } as any;
		});
		const lastEnd = this.currentAvailabilities[this.currentAvailabilities.length - 1]?.endDateTime;

		if (lastEnd && selectedCalendarDate.getDate() === new Date(lastEnd).getDate()) {
			ranges.push({
				start: lastEnd as string,
				end: endOfDay,
			});
		}

		if (!ranges.length) {
			ranges.push({ start: startOfDay, end: endOfDay });
		}

		return ranges;
	}

	onCalendarChange(date: string) {
		this.finalDate = null;
		this.initialDate = null;
		this.currentCalendarDate = date;
	}

	onInitialDateChange(date: Date) {
		this.finalDate = null;
		this.initialDate = date;
	}

	removeAvailability(tag: string) {
		if (this.disabled) {
			return;
		}
		const availabilityIndex = this.value.findIndex(
			availability => this.formatStartAndEndDateTime(availability.startDateTime, availability.endDateTime) === tag,
		);
		this.value.splice(availabilityIndex, 1);
		this.$emit("input", this.value);
	}

	addAvailability() {
		const availabilities: IAvailability[] = [];
		if (this.timeDivisionInMinutes) {
			let start = this.initialDate!;
			const end = this.finalDate!;
			while (isBefore(start, end)) {
				const nextStart = addMinutes(start, this.timeDivisionInMinutes);
				availabilities.push({
					startDateTime: start.toISOString(),
					endDateTime: nextStart.toISOString(),
					modality: this.modality as AvailabilityModality,
				});
				start = nextStart;
			}
		} else {
			availabilities.push({
				startDateTime: this.initialDate!.toISOString(),
				endDateTime: this.finalDate!.toISOString(),
				modality: this.modality as AvailabilityModality,
			});
		}
		if (this.weeksQuantityToRepeat) {
			availabilities.forEach(({ startDateTime, endDateTime, modality }) => {
				for (let index = 0; index < this.weeksQuantityToRepeat!; index++) {
					availabilities.push({
						startDateTime: addWeeks(new Date(startDateTime), index + 1).toISOString(),
						endDateTime: addWeeks(new Date(endDateTime), index + 1).toISOString(),
						modality,
					});
				}
			});
		}

		const overlapsValidations: boolean[] = [];
		availabilities.forEach(newAvailability => {
			const { startDateTime: newStartDateTime, endDateTime: newEndDateTime } = newAvailability;
			this.value.forEach(availability => {
				const { startDateTime, endDateTime } = availability;
				overlapsValidations.push(
					areIntervalsOverlapping(
						{ start: new Date(newStartDateTime), end: new Date(newEndDateTime) },
						{ start: new Date(startDateTime), end: new Date(endDateTime) },
					),
				);
			});
		});

		if (overlapsValidations.some(overlapValidation => overlapValidation)) {
			showErrorAlert("Não foi possível incluir horários, valide os intervalos e tente novamente");
		} else {
			const orderedAvailabilities = [...this.value, ...availabilities].sort(
				({ startDateTime: start }, { startDateTime: nextStart }) => {
					return isBefore(new Date(start), new Date(nextStart)) ? -1 : 1;
				},
			);
			this.$emit("input", orderedAvailabilities);
		}

		Object.assign(this, {
			initialDate: null,
			finalDate: null,
			modality: null,
			timeDivisionInMinutes: null,
			weeksQuantityToRepeat: null,
		});
	}

	formatStartAndEndDateTime(startDateTime: string | Date, endDateTime: string | Date) {
		return `${format(new Date(startDateTime), "dd/MM/yyyy")} - ${format(
			new Date(startDateTime),
			"HH:mm",
		)} até ${format(new Date(endDateTime), "HH:mm")}`;
	}
}
