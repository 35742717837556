









import { Component, Prop, Vue } from "vue-property-decorator";

export type tagVariant = "primary" | "secondary" | "tertiary" | "quaternary";

@Component({})
export default class CustomTag extends Vue {
	@Prop({ default: "" }) label!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "primary" }) variant!: tagVariant;
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event, buttonAction?: string) => void;
	@Prop({ default: () => null }) onTranslated!: Function;

	baseCss =
		"border-1 rounded-md text-sm text-center px-2 py-1.5 h-auto font-bold flex justify-center whitespace-nowrap";
	disabledCss =
		"border-1 rounded-md text-sm w-auto whitespace-nowrap text-center px-2.5 py-1.5 h-auto font-bold flex justify-center border-journey-gray text-journey-gray";
	primaryCss = "border-journey-green text-journey-green";
	secondaryCss = "border-journey-orange text-journey-orange";
	tertiaryCss = "border-journey-dark-gray text-journey-dark-gray";
	quaternaryCss = "border-journey-red text-journey-red";

	get tagStyle() {
		let baseStyles = [this.baseCss];
		switch (this.variant) {
			case "primary":
				baseStyles.push(this.primaryCss);
				break;
			case "secondary":
				baseStyles.push(this.secondaryCss);
				break;
			case "tertiary":
				baseStyles.push(this.tertiaryCss);
				break;
			case "quaternary":
				baseStyles.push(this.quaternaryCss);
				break;
		}
		if (this.disabled) {
			baseStyles = [this.disabledCss];
		}
		return baseStyles;
	}

	handleClick(event: Event) {
		if (this.disabled) {
			return;
		}
		event.preventDefault();
		if (this.onClick && typeof this.onClick === "function") {
			this.onClick(event);
		}
	}
}
