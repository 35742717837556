var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full overflow-auto scroll"},[_c('table',{staticClass:"relative w-full"},[_c('tr',{staticClass:"bg-journey-white shadow-md rounded-lg sticky top-0 z-10 text-base text-journey-black"},[(_vm.variant === 'secondary')?_c('th',{staticClass:"px-2",staticStyle:{"width":"60px"}},[_c('custom-checkbox',{attrs:{"inputValue":"selectAll","onChange":_vm.toggleSelectAll},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1):_vm._e(),_vm._l((_vm.headers),function(header,headerIndex){return _c('th',{key:headerIndex,staticClass:"relative",style:(header.kind !== 'invisible'
						? ("width: " + (header.width) + "; min-width: " + (header.width)) || 'min-width: 120px'
						: 'width:0px')},[(header.kind === 'invisible')?_c('div'):(header.kind !== 'select' && !header.hasSelect)?_c('div',{staticClass:"flex gap-x-2 flex px-1 cursor-pointer",on:{"click":function($event){return _vm.clickItemHeader(header)}}},[(header.icon)?_c('div',[_c('img',{staticClass:"w-6",attrs:{"src":require(("../../assets/icons/" + (header.icon))),"alt":""}})]):_c('div',[_vm._v(" "+_vm._s(header.name)+" ")])]):(header.kind === 'select' || header.hasSelect)?_c('div',{staticClass:"flex px-1",attrs:{"id":"itemMenu"}},[[(header.icon)?_c('img',{staticClass:"w-6",attrs:{"src":require(("../../assets/icons/" + (header.icon))),"alt":header.icon}}):_c('span',[_vm._v(_vm._s(header.name))])],_c('img',{staticClass:"cursor-pointer ml-1",attrs:{"src":require("../../assets/icons/arrow-down.svg"),"alt":"arrow down"},on:{"click":function($event){return _vm.openSubMenu(header)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(header === _vm.subMenuOpen),expression:"header === subMenuOpen"}],staticClass:"max-h-60 overflow-auto scroll",class:['bg-journey-white shadow rounded-lg z-50 absolute top-14 flex flex-col', header.subMenuStyle],attrs:{"id":"subMenu"}},_vm._l((_vm.addOptionAll(header.options)),function(option,optionIndex){return _c('div',{key:optionIndex,staticClass:"px-2 cursor-pointer",class:[{ 'mt-1': optionIndex === 0, 'mb-1': header.options.length === optionIndex }],on:{"click":function($event){return _vm.onClick({ key: header.key, value: option.value })}}},[_c('div',{staticClass:"flex gap-3"},[(option.name)?_c('span',[_vm._v(_vm._s(option.name))]):_vm._e(),(option.icon)?_c('img',{class:option.iconStyle,attrs:{"src":require(("../../assets/icons/" + (option.icon))),"alt":""}}):_vm._e()]),(header.options.length > optionIndex)?_c('hr'):_vm._e()])}),0)],2):_vm._e()])})],2),_c('tbody',[_vm._l((_vm.tableData),function(data,dataIndex){return _c('tr',{key:dataIndex,staticClass:"pt-10",class:[
					{
						'border-b': _vm.tableData.length > dataIndex + 1,
						'cursor-pointer hover:bg-journey-light-gray': _vm.onClickLine,
					},
					_vm.primaryBody ]},[(_vm.variant === 'secondary')?_c('td',{staticClass:"px-2"},[_c('custom-checkbox',{attrs:{"inputValue":data,"onChange":_vm.toggleSelect},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),_vm._l((_vm.headers),function(header,headerIndex){return _c('td',{key:headerIndex,class:header.kind !== 'invisible' ? 'py-2 px-1' : 'p-0',on:{"click":function($event){_vm.onClickLine ? _vm.onClickLine(data) : null}}},[_c('div',{class:header.kind !== 'customTag' ? 'flex items-center gap-x-2' : 'block'},[(!header.kind || header.kind === 'text' || header.kind === 'select' || header.kind === 'relation')?_c('div',{class:_vm.tableRowStyle(header.kind) || _vm.tableRowStyle(header.style)},[_vm._v(" "+_vm._s(header.translated ? header.translated(data[header.key]) : header.onTransform ? header.onTransform(data[header.key]) : _vm.getFieldContent(header, header.key, data, header.relation))+" ")]):_vm._e(),(header.kind === 'actions')?_c('div',{staticClass:"flex flex-row"},_vm._l((header.actions),function(action,actionsIndex){return _c('div',{key:actionsIndex,staticClass:"flex flex-col justify-center px-0.5"},[(action.type === 'GuideBalloon')?_c('GuideBalloon',{attrs:{"isStaticBalloon":true,"text":action.text,"iconStyle":'fil',"featherIcon":{
										icon: action.icon,
										size: '18',
									}},on:{"click":function($event){return _vm.actionsEvent(action.eventName, data)}}}):(action.type === 'VueIcon')?_c('p',{class:action.style ? action.style : 'h4',on:{"click":function($event){return _vm.actionsEvent(action.eventName, data)}}},[_c('b-icon',{attrs:{"icon":action.icon,"variant":"dark"}})],1):_vm._e()],1)}),0):_c('data-table-custom',{attrs:{"data":data,"header":header}}),(header.keyAlert && !data[header.keyAlert])?_c('span',{staticClass:"relative"},[(header.guideText)?_c('GuideBalloon',{attrs:{"icon":"alert","text":header.guideText ? header.guideText : 'Cadastro incompleto',"balloonStyle":"w-max"}}):_vm._e()],1):_vm._e()],1)])})],2)}),(!_vm.tableData.length)?_c('tr',[_vm._m(0)]):_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"text-center text-sm py-2.5 px-1.5 mb-6",attrs:{"colspan":"100%"}},[_c('span',[_vm._v("Nenhum dado para exibir")])])}]

export { render, staticRenderFns }